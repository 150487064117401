import { Link } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const fmTitle = "Az egyéni körzetekben fog eldőlni a 2022-es választás";
const fmSlug = "egyeni-korzetekben-fog-eldolni-a-2022-es-valasztas";
const fmDate = "2021. szeptember 24.";

export const EgyeniKorzetekbenAbstract = () => (
	<section>
		<Link to={`/blog/${fmSlug}`}>
			<h1 className="text-2xl leading-tight font-semibold cursor-pointer">{fmTitle}</h1>
		</Link>
		<p className="text-gray-500 mt-2">{fmDate}</p>
		<div className="mt-4">
			<span>
				Az őszi előválasztásokon az ellenzéki szavazók kiválasztották a közös miniszterelnök-jelöltet, és a 106
				egyéni körzetben induló képviselőjelöltet. A kétfordulós miniszterelnök-jelölti csatát kísérte a
				legnagyobb figyelem, viszont nem túlzás azt állítani, hogy a 2022-es választások kimenetele
				szempontjából az egyéni képviselőjelöltek szerepe lesz döntő:
			</span>
			<ul className="mt-4 list-disc list-outside ml-8">
				<li>
					A parlamenti mandátumok 70%-a az egyéni jelöltekre adott szavazatok alapján kerül kiosztásra. Egy
					győztes egyéni jelöltre leadott szavazat átlagos értéke 3.5-szerese az országos listás
					szavazatoknak.
				</li>
				<li className="mt-4">
					Egyéni körzetekben kevés szavazat nagy hatást tud elérni. Országos listán egy mandátumhoz 93 ezer
					szavazat szükséges. Egyéniben a legszorosabb körzetek általában pár száz szavazaton múlnak: 93 ezer
					“jól irányzott” szavazat nagyjából 30 körzetet, azaz az összes körzet majd egyharmadát billentené
					át.
				</li>
			</ul>
		</div>
		<div className="mt-4">
			<div className="flex justify-between">
				<div></div>
				<div>
					<Link className="link" to={`/blog/${fmSlug}`}>
						tovább ⟶
					</Link>
				</div>
			</div>
		</div>
	</section>
);

const FullArticle = () => (
	<section>
		<h1 className="text-2xl leading-tight font-semibold">{fmTitle}</h1>
		<p className="text-gray-500 mt-2">{fmDate}</p>
		<div className="mt-4">
			<span>
				Az őszi előválasztásokon az ellenzéki szavazók kiválasztották a közös miniszterelnök-jelöltet, és a 106
				egyéni körzetben induló képviselőjelöltet. A kétfordulós miniszterelnök-jelölti csatát kísérte a
				legnagyobb figyelem, viszont nem túlzás azt állítani, hogy a 2022-es választások kimenetele
				szempontjából az egyéni képviselőjelöltek szerepe lesz döntő:
			</span>
			<ul className="mt-4 list-disc list-outside ml-8">
				<li>
					A parlamenti mandátumok 70%-a az egyéni jelöltekre adott szavazatok alapján kerül kiosztásra. Egy
					győztes egyéni jelöltre leadott szavazat átlagos értéke 3.5-szerese az országos listás
					szavazatoknak.
				</li>
				<li className="mt-4">
					Egyéni körzetekben kevés szavazat nagy hatást tud elérni. Országos listán egy mandátumhoz 93 ezer
					szavazat szükséges. Egyéniben a legszorosabb körzetek általában pár száz szavazaton múlnak: 93 ezer
					“jól irányzott” szavazat nagyjából 30 körzetet, azaz az összes körzet majd egyharmadát billentené
					át.
				</li>
			</ul>
		</div>
		<p className="my-16">
			<span className="p-4 m-8 font-bold text-xl block bg-gray-400">
				Egyéni szavazatok: a mandátumok 70%-át határozzák meg
			</span>
		</p>
		<p className="md:mx-32">
			<iframe
				title="Miből lesz a mandátum?"
				aria-label="chart"
				id="datawrapper-chart-Wmndc"
				src="https://datawrapper.dwcdn.net/Wmndc/2/"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "590px" }}
			></iframe>
		</p>
		<p className="mt-4">
			A parlament létszáma 199 fő: 106 egyéni körzet van és 93 listás mandátum, azaz a mandátumok 53%-a egyéni
			mandátum és 47%-a listás. Viszont a listás mandátumkiosztásnál az egyéni szavazatok is számítanak a
			kompenzációs rendszer miatt. Az egyszerűség kedvéért első körben a számolásnál tekintsünk el a határon túli
			levélszavazatokról és a nemzetiségi mandátum hatásáról, illetve a parlamenti küszöböt el nem érő pártokra
			adott szavazatoktól.
		</p>
		<p className="mt-4">
			Ha a 2018-as eredményekből indulunk ki és feltesszük hogy minden körzetben egy kormánypárti és egy ellenzéki
			jelölt csap össze, akkor van nagyjából 5.3 millió egyéni és 5.3 millió listás szavazat. Az 5.3 millió
			egyéniből 3 millió a győztesre és 2.3 millió a vesztesre szavaz. Kétpólusú küzdelem esetén a kompenzációs
			szavazatok száma megegyezik a győztes jelöltekre leadott szavazatok számával, hiszen 2.3 millió
			veszteskompenzációs és közel 700 ezer győzteskompenzációs szavazat van. Tehát mondhatjuk azt, hogy 3 /
			(3+5.3) x 93 ~= 33 listás mandátum az egyéni szavazatokból jön, és összességében 139 mandátum, azaz a
			parlamenti helyek 70%-a származik az egyéni szavazatokból.
		</p>
		<p className="mt-4">
			A részletes számítás, mely a határon túliak levélszavazatát és a nemzetiségi mandátum hatását is
			tartalmazza, az alábbi ábrán látható. Érdekesség, hogy egy győztes jelöltre adott egyéni szavazat súlya
			3.5-szerese egy bejutó pártra adott listás szavazaténak: előbbinél átlagban 27 ezer szavazat után jár egy
			mandátum, míg az utóbbinál 93 ezer. Mivel egy bejutó párt vesztes jelöltjéért is jár egy kompenzációs
			szavazat, egy ilyen szavazat értéke megegyezik egy bejutó pártra adott listás szavazattal. A kedvezményes
			nemzetiségi listáról szerzett mandátum súlya a 2018-as eredményeket figyelembe véve megegyezik az egyéni
			győztes szavazat súlyával, de ez a súly a pontos szavazatok számától függően sokat változhat.
		</p>
		<p className="mt-8">
			<iframe
				title="Mandátum számítás"
				aria-label="table"
				id="datawrapper-chart-j78AD"
				src="https://datawrapper.dwcdn.net/j78AD/5/"
				scrolling="yes"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "647px" }}
				height="409"
			></iframe>
		</p>
		<p className="my-16">
			<span className=" p-4 m-8 font-bold text-xl block bg-gray-400">
				Egyéni körzetek: kevés szavazat, nagy hatás
			</span>
		</p>
		<p>
			Az alábbi ábrák az Összefogás illetve a Fidesz előnyét mutatják a 106 egyéni körzetben. A legszorosabb
			körzetekben párszáz szavazaton múlik egy-egy mandátum sorsa. Amíg listán 93 ezer szavazat kell egy mandátum
			megszerzéséhez, addig a Fidesz 28, míg az ellenzék 34 egyéni körzetet tudna átbillenteni 93 ezer szavazat
			segítségével, ha a 2018-as választások eredményéből indulunk ki és feltételezzük, hogy az akkor ellenzéki
			szavazók mind a közös listára szavaznának. Természetesen a valóságban nem lehet ilyen jól célzott kampányt
			lefolytatni, már csak azért sem, mert előre nem lehet biztosan tudni, hogy pontosan melyek lesznek a szoros
			körzetek. Viszont ez is megmutatja, hogy mennyire fontosak a jó egyéni jelöltek a végeredmény szempontjából.
		</p>
		<p className="mt-8">
			<iframe
				title="Az Összefogás előnye az ellenzéki egyéni körzetekben"
				aria-label="Stacked Column Chart"
				id="datawrapper-chart-NPUsS"
				src="https://datawrapper.dwcdn.net/NPUsS/8/"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "500px" }}
				height="500"
			></iframe>
		</p>
		<p className="mt-8">
			<iframe
				title="A Fidesz előnye a kormánypárti egyéni körzetekben"
				aria-label="Grouped Column Chart"
				id="datawrapper-chart-Poa3s"
				src="https://datawrapper.dwcdn.net/Poa3s/9/"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "500px" }}
				height="500"
			></iframe>
		</p>
	</section>
);

export default () => {
	return (
		<Layout>
			<SEO title={fmTitle} />
			<div className="container px-4 py-8">
				<FullArticle />
			</div>
		</Layout>
	);
};
