import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { EgyeniKorzetekbenAbstract } from "./blog/egyeni-korzetekben-fog-eldolni-a-2022-es-valasztas";
import { ValasztasiRendszerAbstract } from "./blog/valasztasi-rendszer";
import { CsataterKorzetekAbstract } from "./blog/csatater-korzetek";
import { EllenzekiGyozelemVagyFideszKetharmadAbstract } from "./blog/ellenzeki-gyozelem-vagy-fidesz-ketharmad";

const IndexPage = () => {
	return (
		<Layout>
			<SEO title="Kezdőlap" />
			<div className="container px-4 py-8">
				<div className="grid grid-cols-1 md:grid-cols-3">
					<div className="md:col-span-3 md:border-r-0 md:border-gray-400 md:pr-0 space-y-8">
						<div>
							<EllenzekiGyozelemVagyFideszKetharmadAbstract />
						</div>
						<div>
							<CsataterKorzetekAbstract />
						</div>
						<div>
							<EgyeniKorzetekbenAbstract />
						</div>
						<div>
							<ValasztasiRendszerAbstract />
						</div>
					</div>
					{/* <div className="md:ml-4">
						<section>
							<h2 className="text-gray-500">Ez egy blokk cím</h2>
							<p className="mt-4">
								A dültés szekantikájával dzség öltség tala a harizások bujgatják pitymatás hedúr múmium
								glap, omlan tublagást, a szelemlés szérium kedős kölését. A dültés egyéni könyörzése
								bitusáért a latladott polymász szürjés voktri férter dültés karas kölése reli.
							</p>
						</section>
						<hr className="section-divider" />
						<section>
							<h2 className="text-gray-500 mt-4">Ez egy blokk cím</h2>
							<div className="mt-4 text-center md:text-left">
								<img className="inline-block" src="https://dummyimage.com/330x200/e5edfb/aaa/" />
							</div>
						</section>
						<hr className="section-divider" />
						<section>
							<h2 className="text-gray-500 mt-4">Ez egy blokk cím</h2>
							<h3 className="mt-4 text-xl leading-tight font-semibold">Kisebb cím</h3>
							<p className="my-4">
								A dültés szekantikájával dzség öltség tala a harizások bujgatják pitymatás hedúr múmium
								glap, omlan tublagást, a szelemlés szérium kedős kölését. A dültés egyéni könyörzése
								bitusáért a latladott polymász szürjés voktri férter dültés karas kölése reli.
							</p>
						</section>
						<hr className="section-divider" />
						<section>
							<h2 className="text-gray-500 mt-4">Ez egy blokk cím</h2>
							<div className="mt-4 text-center md:text-left">
								<img className="inline-block" src="https://dummyimage.com/330x300/e5edfb/aaa/" />
							</div>
						</section>
					</div> */}
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;
